import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FantasyGamePanelComponent } from './components/fantasy-game-panel/fantasy-game-panel.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FantasyPlayerSelectComponent } from './components/fantasy-player-select/fantasy-player-select.component';
import { RouterModule } from '@angular/router';
import { FantasyHistoryListComponent } from './components/fantasy-history-list/fantasy-history-list.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FantasyHistoryListItemComponent } from './components/fantasy-history-list-item/fantasy-history-list-item.component';
import { FantasyPlayerSelectConfirmComponent } from './components/fantasy-player-select-confirm/fantasy-player-select-confirm.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { FantasyLeaderboardComponent } from './components/fantasy-leaderboard/fantasy-leaderboard.component';
import { FantasyGamePanelNextGameComponent } from './components/fantasy-game-panel-next-game/fantasy-game-panel-next-game.component';

@NgModule({
  declarations: [
    FantasyGamePanelComponent,
    FantasyPlayerSelectComponent,
    FantasyHistoryListComponent,
    FantasyHistoryListItemComponent,
    FantasyPlayerSelectConfirmComponent,
    FantasyLeaderboardComponent,
    FantasyGamePanelNextGameComponent,
  ],
  exports: [
    FantasyGamePanelComponent,
    FantasyPlayerSelectComponent,
    FantasyHistoryListComponent,
    FantasyGamePanelNextGameComponent,
    FantasyLeaderboardComponent,
  ],
  imports: [RouterModule, SharedModule, CommonModule, CarouselModule, InlineSVGModule.forRoot()],
})
export class FantasyModule {}
