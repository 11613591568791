<div class="full-page container-fluid w-100 d-flex">
  <div class="m-auto text-center">
    <img src="assets/images/logo-secondary.svg" class="logo mb-5" />
    <ng-container *ngIf="!error">
      <h4 class="text-primary fw-bold mb-3">{{ 'login.title' | translate }}</h4>
      <p class="text-black font-size-14 px-4">{{ 'login.description' | translate }}</p>
    </ng-container>
    <!--Error log in-->
    <ng-container *ngIf="!!error">
      <p class="text-danger font-size-14">{{ error }}</p>
    </ng-container>
  </div>
</div>
