<!-- Navbar -->
<ul class="nav nav-secondary account-details-nav">
  <a class="nav-item ms-0" [routerLink]="['/']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <span class="icon material-icons">flag</span>
    <label class="font-size-14">Challenges</label>
  </a>

  <a class="nav-item me-0" [routerLink]="['/badges']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <span class="icon material-icons">local_police</span>
    <label class="font-size-14">Achievements</label>
  </a>
</ul>
