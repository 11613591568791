<div
  class="date-range-picker d-flex flex-column flex-lg-row align-items-center justify-content-end"
  *ngIf="ngbStartDate && ngbEndDate"
>
  <div class="d-flex flex-column date-col">
    <div class="from-date-text fw-bold text-start">From date</div>
    <div class="input-group mb-2">
      <input
        class="form-control date-field"
        name="startDate"
        [(ngModel)]="ngbStartDate"
        ngbDatepicker
        [minDate]="ngbMinDate"
        [maxDate]="ngbEndDate"
        #startDatePicker="ngbDatepicker"
        (click)="startDatePicker.toggle()"
      />
      <div class="input-group-append">
        <button
          class="btn"
          type="button"
          attr.aria-label="{{ 'date.picker.start.date.screenreader' | translate }}"
          (click)="startDatePicker.toggle()"
        >
          <span class="icon material-icons" aria-hidden="true">today</span>
        </button>
      </div>
    </div>
  </div>
  <div class="d-none d-lg-flex flex-column text-center mt-2 mx-3">
    <span class="icon material-icons transaction-arrow" aria-hidden="true">multiple_stop</span>
  </div>
  <div class="d-flex flex-column date-col">
    <div class="to-date-text text-start fw-bold mt-4 mt-lg-0">To date</div>
    <div class="input-group mb-2">
      <input
        ngbDatepicker
        #endDatePicker="ngbDatepicker"
        class="form-control date-field"
        name="endDate"
        [(ngModel)]="ngbEndDate"
        [maxDate]="today"
        [minDate]="ngbStartDate"
        (click)="endDatePicker.toggle()"
      />

      <div class="input-group-append">
        <button
          class="btn"
          attr.aria-label="{{ 'date.picker.end.date.screenreader' | translate }}"
          type="button"
          (click)="endDatePicker.toggle()"
        >
          <span class="icon material-icons" aria-hidden="true">event</span>
        </button>
      </div>
    </div>
  </div>
</div>
