<div class="full-page container-fluid w-100 d-flex">
  <div class="text-center maintenance-container">
    <img src="assets/images/logo-black.svg" class="logo mb-5" />
    <div class="maintenance-description mt-5">
      <div class="text-primary icon material-icons mb-3">engineering</div>
      <h4 class="text-primary mb-3">{{ 'maintenancepage.title' | translate }}</h4>
      <p
        class="text-black font-size-14 px-4"
        [translate]="'maintenancepage.description'"
        [translateParams]="{ fgbdefault: 'Sorry, we’re down for scheduled maintenance right now. Please check back later' }"
      ></p>
    </div>
  </div>
</div>
