import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbDateNativeAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [NgbDateNativeAdapter],
})
export class DateRangePickerComponent implements OnInit {
  constructor(private adapter: NgbDateNativeAdapter) {}

  @Input() startDate: Date;
  @Output() startDateChange = new EventEmitter<Date | null>();

  @Input() endDate: Date;
  @Output() endDateChange = new EventEmitter<Date | null>();

  today: NgbDateStruct;
  // tslint:disable-next-line: variable-name
  private _startDate: NgbDateStruct | null;
  // tslint:disable-next-line: variable-name
  private _endDate: NgbDateStruct | null;

  ngOnInit() {
    let currentDate = new Date(Date.now());
    this.ngbStartDate = this.adapter.fromModel(this.startDate);
    this.ngbEndDate = this.adapter.fromModel(this.endDate);

    this.today = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate(),
    };
  }

  get ngbStartDate() {
    return this._startDate;
  }
  set ngbStartDate(value: NgbDateStruct | null) {
    this._startDate = value;
    this.startDateChange.emit(this.adapter.toModel(value));
  }

  get ngbEndDate() {
    return this._endDate;
  }
  set ngbEndDate(value: NgbDateStruct | null) {
    this._endDate = value;
    this.endDateChange.emit(this.adapter.toModel(value));
  }
}
