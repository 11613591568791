<!--Banner with title-->
<ng-container *ngIf="memberDetails$ | async as memberDetails; else loadingRef">
  <div class="banner">
    <div class="banner-item">
      <div class="banner-title">{{ 'banner.title.heading' | translate }} {{ memberDetails.FirstName }}</div>
      <div class="banner-welcome">{{ memberDetails.EmailAddress1 }}</div>
    </div>
  </div>
</ng-container>

<ng-template #loadingRef>
  <fgb-loading></fgb-loading>
</ng-template>
