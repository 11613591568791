<!-- Banner -->
<fgb-banner [title]="'banner.payment.page.title'"></fgb-banner>

<div class="container">
  <!--Back btn-->
  <a role="link" class="text-white din-bold my-4 cursor-pointer" routerLink="/">
    <span class="icon icon-arrow-1-left fw-bold pe-1 font-size-12"></span
    ><span class="font-size-14">{{ 'back.payment.page' | translate }}</span>
  </a>

  <!-- Barcode -->
  <ng-container *ngIf="barcode$ | async as barcode">
    <ng-container *ngIf="card$ | async as card">
      <div class="barcode-container d-flex flex-column justify-content-center align-items-center align-self-center mx-auto mt-4">
        <qrcode
          [qrdata]="barcode.Barcode"
          [errorCorrectionLevel]="'M'"
          [elementType]="'svg'"
          [margin]="0"
          [width]="84"
          [allowEmptyString]="true"
        ></qrcode>
        <div class="barcode-account mt-2">
          <span class="account-label fw-bold font-size-12">{{ 'payment.page.barcode.account.label' | translate }}</span>
          <span class="account-no font-size-14 ms-2">{{ card?.ExternalRefNumber }}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="my-4">
    <fgbcl-card-token-list
      [regex]="regex"
      [provider]="provider"
      [redirectUrl]="'/cardtoken/result'"
      [failureUrl]="'/card/failure'"
      [showCloseBtn]="true"
    >
    </fgbcl-card-token-list>
  </div>

  <!-- How it works -->
  <div class="ecash-description-box bg-primary shadow px-4 py-3">
    <h5 class="text-primary text-center font-size-16 text-white fw-bold">
      {{ 'payment.description.title' | translate }}
    </h5>
    <hr class="vertical-line" />
    <div class="d-flex flex-row flex-wrap flex-lg-nowrap justify-content-lg-around mt-3">
      <!--Col 1-->
      <div class="d-flex flex-column flex-nowrap me-2 py-2 w-100">
        <div class="d-flex flex-row icon-wrapper">
          <div class="ecash-description-icon centered position-relative">
            <div class="ecash-description-number position-absolute centered text-white">1</div>
            <span class="icon material-icons ecash-description ecash-description-icon-1 text-white" aria-hidden="true"
              >credit_card</span
            >
          </div>
          <div class="font-size-12 d-flex align-self-center ps-3 pe-lg-4 text-white payment-description-1">
            {{ 'payment.description.step.one' | translate }}
          </div>
        </div>
      </div>
      <!--Col 2-->
      <div class="d-flex flex-column flex-nowrap me-2 py-2 w-100">
        <div class="d-flex flex-row icon-wrapper">
          <div class="ecash-description-icon centered position-relative">
            <div class="ecash-description-number position-absolute centered text-white">2</div>
            <span class="icon material-icons ecash-description ecash-description-icon-2 text-white" aria-hidden="true"
              >qr_code_scanner</span
            >
          </div>
          <div class="font-size-12 d-flex align-self-center ps-3 pe-lg-4 text-white payment-description-2">
            {{ 'payment.description.step.two' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Transaction History -->
  <div class="my-4">
    <fgb-transaction-history
      [transactionTypes]="[transactionTypes.ecash, transactionTypes.payment]"
      [ecashView]="true"
    ></fgb-transaction-history>
  </div>
</div>
