<!-- Banner -->
<fgb-banner></fgb-banner>

<!--Back -->
<div class="container">
  <button class="btn text-white fw-bold d-flex ps-0 align-items-center" routerLink="/">
    <span class="icon material-icons left-icon">chevron_left</span>
    <span class="font-size-14 font-heading">{{ 'back.terms.page' | translate }}</span>
  </button>
</div>

<div class="position-relative">
  <img class="cog1" src="assets/images/404_cog.png" />
  <img class="cog2" src="assets/images/404_cog.png" />
  <img src="assets/images/404_laptop.png" class="d-block mx-auto mt-4" style="max-width: 300px" />
  <h1 class="text-center text-white">Page not found...</h1>
</div>
