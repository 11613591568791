import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, LoggingService } from '@fgb/core';
import { ErrorStateService } from '@fgb/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fgb-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  error: string = '';
  private _destroyed$ = new Subject<void>();

  constructor(
    private router: Router,
    public errorService: ErrorStateService,
    private authService: AuthService,
    public deviceDetector: DeviceDetectorService,
    private route: ActivatedRoute,
    public logService: LoggingService,
    private translate: TranslateService
  ) {
    this.authService.checkApiStatus();

    if (this.authService.hasCurrentRefreshToken()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      if (params.keys.includes('ssocode')) {
        this.logService.error(`SSO failed. CODE ${params.get('ssocode')}`);

        this.translate
          .stream('login.failure.text')
          .pipe(takeUntil(this._destroyed$))
          .subscribe((data: string) => {
            this.error = data;
          });
      }
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
