<!-- BANNER -->
<fgb-banner></fgb-banner>
<div class="container">
  <!--Back btn-->
  <button class="btn text-white fw-bold d-flex ps-0 align-items-center" routerLink="/">
    <span class="icon material-icons left-icon">chevron_left</span>
    <span class="font-size-14 font-heading">{{ 'back.terms.page' | translate }}</span>
  </button>
  <!--Terms-->
  <div class="row">
    <div class="col-12">
      <fgbcl-terms-and-conditions [type]="termsAndConditionsType" [defaultTerms]="defaultTemplate"> </fgbcl-terms-and-conditions>
    </div>
  </div>
</div>

<ng-template #defaultTemplate>
  <p class="terms-section-content text-start mb-3">{{ 'default.terms.text' | translate }}</p>
</ng-template>
