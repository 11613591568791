import { Pipe, PipeTransform } from '@angular/core';
import * as markdown from 'marked';

@Pipe({
  name: 'markdownTranslate',
})

/**
 * This pipe is used to transform
 * markdown string to html.
 * To markdown translated text to html
 * you must have the translate and markdownTranslate
 * pipe in the correct order, as shown in
 * the example below. This will then apply the
 * markdown pipe to the translated text.
 *
 * Example of usage:
 * [innerHTML]="'registration.success.text' | translate | markdownTranslate"
 */
export class MarkdownTranslatePipe implements PipeTransform {
  transform(value: string): string {
    if (value && value.length > 0) {
      return markdown.marked(value);
    }

    return value;
  }
}
