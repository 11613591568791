<!-- Banner -->
<fgb-banner></fgb-banner>
<!-- Navbar -->
<div class="container">
  <fgb-account-nav></fgb-account-nav>
</div>
<!-- Badge overview list -->
<h1 class="accessibility-only">Badges</h1>
<div class="container mt-4">
  <fgbcl-badge-overview-list [confettiColors]="['#8921fe', '#7ec6fe']" [isSponsorInBody]="true"></fgbcl-badge-overview-list>
</div>
