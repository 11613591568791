import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-badges-page',
  templateUrl: './badges-page.component.html',
  styleUrls: ['./badges-page.component.scss'],
})
export class BadgesPageComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
