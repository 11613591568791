<!-- BANNER -->
<fgb-banner></fgb-banner>

<div class="container">
  <!--Back btn-->
  <button class="btn text-white fw-bold d-flex ps-0 align-items-center" routerLink="/">
    <span class="icon material-icons left-icon">chevron_left</span>
    <span class="font-size-14 font-heading">{{ 'back.terms.page' | translate }}</span>
  </button>
  <!--FAQs-->
  <div class="row faq-container">
    <div class="col-12">
      <fgbcl-faq-section></fgbcl-faq-section>
    </div>
  </div>
</div>
