import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StepType, RegistrationStepService } from '@fgb/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
})
export class HowItWorksComponent implements OnInit {
  routerLink: string = '';
  constructor(private route: ActivatedRoute, private registrationStepService: RegistrationStepService, private router: Router) {}

  ngOnInit(): void {
    this.isRedirectFormHomePage ? this.getLinkText(true) : this.getLinkText(false);
  }

  getLinkText(boolean: boolean) {
    boolean && (this.routerLink = 'back.to.challenges');
    !boolean && (this.routerLink = 'let.started');
  }

  get isRedirectFormHomePage() {
    return this.route.snapshot.queryParamMap.get('isHomePage');
  }

  submitStep() {
    if (this.isRedirectFormHomePage) {
      this.router.navigate(['/']);
    } else {
      firstValueFrom(this.registrationStepService.submitRegistrationStepData(StepType.Marketing, { Accepted: true }));
    }
  }
}
