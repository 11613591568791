import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: 'card-failure-page.component.html',
  styleUrls: ['card-failure-page.component.scss'],
  selector: 'fgb-card-failure',
})
export class CardFailurePageComponent implements OnInit {
  ngOnInit() {}
}
