<div class="how-it-works">
  <img src="assets/images/kings-white.svg" alt="" />
  <div class="my-4">
    <div class="title">{{ 'how.it.work.title' | translate }}</div>
    <div class="sub-title" [innerHTML]="'how.it.work.sub.title' | translate | markdownTranslate"></div>
  </div>
  <div class="content">
    <div class="attend-games">
      <div class="material-icons">sports_basketball</div>
      <div class="text">{{ 'how.it.work.attend.game' | translate }}</div>
    </div>
    <div class="rewards">
      <div class="material-icons">local_police</div>
      <div class="text">{{ 'how.it.work.rewards' | translate }}</div>
    </div>
    <div class="redeem">
      <div class="material-icons">phone_iphone</div>
      <div class="text">{{ 'how.it.work.redeem' | translate }}</div>
    </div>
  </div>

  <div class="font-heading font-size-14 text-center mb-4">{{ 'how.it.works.description' | translate }}</div>

  <div class="pb-4 w-100">
    <button class="btn btn-back" (click)="submitStep()">
      <a>{{ routerLink | translate }}</a>
    </button>
  </div>
</div>
