<h3 class="transaction-history-title">{{ 'transaction.history.title' | translate }}</h3>

<div
  class="transaction-history-container card border-black bg-primary"
  [ngClass]="{ 'border-0 shadow py-2 mt-3 mt-lg-0': accountView }"
>
  <div class="card-body p-3">
    <!-- <div>
      <h1 *ngIf="ecashView" class="history-heading d-none d-lg-block">TRANSACTIONS HISTORY</h1>
    </div> -->
    <div class="row">
      <div class="col-12" [ngClass]="{ 'col-lg-7 offset-lg-1': accountView, 'col-lg-9': ecashView }">
        <fgb-date-range-picker [(startDate)]="transactionStartDate" [(endDate)]="transactionEndDate"></fgb-date-range-picker>
      </div>
      <div class="col-12" [ngClass]="{ 'col-lg-2 offset-lg-1': accountView, 'col-lg-3': ecashView }">
        <button
          type="button"
          class="search-button fw-bold mb-2 d-block mx-auto border-0 text-white bg-secondary"
          (click)="search()"
        >
          Search
        </button>
      </div>
    </div>
  </div>
</div>

<fgb-transaction-list
  *ngIf="selectedValues"
  [startDate]="selectedValues.start"
  [endDate]="selectedValues.end"
  [memberId]="memberId"
  [transactionType]="selectedValues.type"
></fgb-transaction-list>

<ng-template #noSearch>
  <div>
    <img alt="" *ngIf="ecashView" class="no-ecash-image d-flex mx-auto" src="assets/images/icons/no-transactions.svg" />
    <h5 *ngIf="ecashView" class="text-primary text-center mt-3 mb-0 fw-bold text-uppercase mt-4 font-size-16">
      Transactions
    </h5>
    <img alt="" *ngIf="accountView" class="no-activity-image d-flex mx-auto" src="assets/images/icons/no-activity.svg" />
    <h5 *ngIf="accountView" class="text-primary text-center mt-3 mb-0 fw-bold text-uppercase mt-4 font-size-16">
      Activity
    </h5>
    <p class="text-info text-center font-size-12">Please select a date range</p>
  </div>
</ng-template>
