import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoggingService, PopulationService, SignalRHub } from '@fgb/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fgb-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.scss'],
})
export class MasterPageComponent implements OnInit, OnDestroy {
  private _destroyed$ = new Subject<void>();
  constructor(private signalRHub: SignalRHub, private logService: LoggingService, private populationService: PopulationService) {}

  ngOnInit() {
    this.signalRHub.connectionEstablished$.pipe(takeUntil(this._destroyed$)).subscribe((connection) => {
      this.logService.debug('SignalR connection', connection);
    });
    this.populationService.fetchPopulations();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
